<template>
  <div class="home">
    <h1>Jeremias Pettinen</h1>
    <ul class="mdc-list mdc-list--two-line mdc-list--avatar-list">
      <li v-for="item in contacts" :key="item.key" class="mdc-list-item" @click="item.click">
        <span class="mdc-list-item__ripple" />
        <Icon class="mdc-list-item__graphic" :icon="icons[item.icon]" aria-hidden="true" role="presentation" />
        <span class="mdc-list-item__text">
          <span class="mdc-list-item__primary-text" v-text="item.primaryText" />
          <span class="mdc-list-item__secondary-text" v-text="item.secondaryText" />
        </span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {Icon} from "@iconify/vue";
import {Options, mixins} from "vue-class-component";

import Icons from "@/mixins/Icons";
import {nnbsp} from "@/utils";


const to = function(href: string): () => void {
  return function(): void {
    location.href = href;
  };
};

@Options({
  components: {Icon},
})
export default class HomeView extends mixins(Icons) {
  created(): void {
    this.loadIcons("ic", [
      "baseline-alternate-email",
      "phone",
    ]);
    this.loadIcons("bx", ["bxl-telegram"]);
    this.loadIcons("mdi", ["twitter"]);
  }

  contacts = [
    {
      key: "email",
      primaryText: "Email",
      secondaryText: "hi@jeremi.as",
      icon: "baseline-alternate-email",
      click: to("mailto:hi@jeremi.as"),
    },
    {
      key: "phone",
      primaryText: "Phone / SMS / Signal / WhatsApp",
      secondaryText: `+358${nnbsp}45${nnbsp}671${nnbsp}1994`,
      icon: "phone",
      click: to("sms:+358456711994"),
    },
    {
      key: "telegram",
      primaryText: "Telegram",
      secondaryText: "@pettinen",
      icon: "bxl-telegram",
      click: to("https://t.me/pettinen"),
    },
    {
      key: "twitter",
      primaryText: "Twitter",
      secondaryText: "@pettinen_",
      icon: "twitter",
      click: to("https://twitter.com/pettinen_"),
    },
  ];
}
</script>

<style lang="scss" scoped>
.home {
  padding: 0 1rem;
}
</style>
